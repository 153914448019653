.profileContainer {
    box-shadow: 0 0 8px black;
    border-radius: 10px;
    overflow: hidden;
    height: 80vh;
    overflow-y: auto;
}

.profileHeader {
    width: 100%;
    height: 25vh;
    background-image: url("https://source.unsplash.com/featured");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 4px solid white;
}

.profileContent {
    width: 100%;
    margin: 0 !important;
    padding: 0 20px !important;
}

.userInfo {
    text-align: center;
}

.userInfo div {
    text-align: center;
}

.userInfo div h4 {
    margin-top: 20px;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 18pt;
    color: #777;
}

.userInfo div p {
    margin-top: -5px;
    margin-bottom: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 11pt;
    color: #aaa;
}

.content .description {
    word-wrap: break-word;
}

.avatarContainer {
    width: 200px;
    height: 200px;
    margin-top: -90px;
    margin-left: auto;
    margin-right: auto;
    border: 5px solid white;
    border-radius: 50%;
    overflow: hidden;
    background-color: #777;
}

.avatarContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    transition: all 0.3s ease-in;
}

.avatarContainer img:hover {
    opacity: 0.8;
    cursor: pointer;
}