.profileContainer {
    box-shadow: 0 0 8px black;
    border-radius: 10px;
    overflow: hidden;
    height: 80vh;
    overflow-y: auto;
}

.profileHeader {
    width: 100%;
    height: 25vh;
    background-image: url("https://source.unsplash.com/featured");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 4px solid white;
}

.profileContent {
    width: 100%;
    margin: 0 !important;
    padding: 0 20px !important;
}

.user {
    text-align: center;
}

.user img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid white;
    margin-top: -120px;
}

.user div {
    text-align: center;
}

.user div h4 {
    margin-top: 20px;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 18pt;
    color: #777;
}

.user div p {
    margin-top: -5px;
    margin-bottom: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 11pt;
    color: #aaa;
}

.content .description {
    word-wrap: break-word;
}

.avatar {
    object-fit: cover;
}