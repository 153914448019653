body {
  margin: 0;
  padding: 0.5rem !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centerText {
  text-align: center;
}

i {
  pointer-events: none;
}

.userDetails {
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  color: black;
}

.userDetails img{
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.userDetails:hover {
  opacity: 0.6;
  cursor: pointer;
  color: black;
}

.userLink:hover {
  opacity: 0.6;
}